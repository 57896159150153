<template>
  <v-dialog
    width="fit-content"
    v-bind="$attrs"
    transition="scale-transition"
    origin="middle center"
    @click:outside="$emit('closeModal')"
  >
    <v-card>
      <h6 v-if="title">{{ title }}</h6>
      <div class="content">
        <slot />
      </div>
      <div v-if="hasButtons" class="buttons">
        <slot name="buttons" />
      </div>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
    },
  },
  computed: {
    hasButtons() {
      return !!this.$slots.buttons;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  width: fit-content;
  padding: $qvo-spacing-16 $qvo-spacing-16 $qvo-spacing-16 $qvo-spacing-24;
  box-shadow: $qvo-shadow-2;
  border-radius: 8px;
  h6 {
    margin-bottom: $qvo-spacing-24;
  }
  .content {
    width: fit-content;
    &::v-deep {
      p {
        color: $qvo-color-grey-600;
      }
    }
  }
  .buttons {
    display: flex;
    margin-top: $qvo-spacing-24;
    justify-content: flex-end;
    gap: $qvo-spacing-8;
    position: sticky;
    bottom: $qvo-spacing-16;
  }
}
</style>